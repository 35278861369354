import type {
  PlasticComparisonUnit,
  TreeComparisonUnit,
  CarbonComparisonUnit,
  KelpComparisonUnit,
  WaterComparisonUnit,
} from '@/stores/interfaces/PublicData'

export const IMPACT_TYPES = ['trees', 'plastic', 'carbon', 'kelp', 'water'] as const
export type ImpactType = (typeof IMPACT_TYPES)[number]

export const ACCOUNT_TYPES = ['ecommerce', 'business', 'personal', 'family', 'admin'] as const
export type AccountType = (typeof ACCOUNT_TYPES)[number]

export const CURRENCY_TYPES = ['euro', 'dollar', 'pound', ''] as const
export type CurrencyType = (typeof CURRENCY_TYPES)[number]

export const PROJECT_STATUSES = ['approved', 'unapproved', 'deprecated'] as const
export type ProjectStatus = (typeof PROJECT_STATUSES)[number]

export const BILLING_PLATFORMS = ['stripe'] as const
export type BillingPlatform = (typeof BILLING_PLATFORMS)[number]

export const BACKGROUND_ICONS = ['leaves', 'circles'] as const
export type BackgroundIconType = (typeof BACKGROUND_ICONS)[number]

export enum BackgroundTheme {
  DARK = 'dark',
  LIGHT = 'light',
  CUSTOM = 'custom',
}

export const HERO_STYLES = ['center', 'side-by-side', 'align-left'] as const
export type HeroStyleType = (typeof HERO_STYLES)[number]

export const ICON_STYLES = ['simple', 'flat', 'isometric'] as const
export type IconStyleType = (typeof ICON_STYLES)[number]

export const LANGUAGES = ['en', 'de'] as const
export type LanguageType = (typeof LANGUAGES)[number]

export const SOCIAL_MEDIA_LINKS = [
  'instagram',
  'facebook',
  'linkedin',
  'website',
  'twitter',
  'tiktok',
] as const
export type SocialMediaType = (typeof SOCIAL_MEDIA_LINKS)[number]

export type TopStat = ImpactType | 'monthsEarthPositive'

export type Currency = 'dollar' | 'euro' | 'pound'

export type PlanType = 'starter' | 'growth' | 'premium'

export type ImpactColor = {
  textColor: string
  backgroundColor: string
}

export type TotalImpact = {
  [key in ImpactType]: number
}

export const PAID_SUBSCRIPTION_TYPES = [
  'ecommerce',
  'ECOMMERCE',
  'starterBusiness',
  'growthBusiness',
  'premiumBusiness',
  'enterpriseBusiness',
  'freeBusiness',
  'starterBusinessYearly',
  'growthBusinessYearly',
  'premiumBusinessYearly',
  'businessLegacy',
] as const

export const FREE_SUBSCRIPTION_TYPES = ['free', 'zero'] as const

export const SUBSCRIPTION_TYPES = [...FREE_SUBSCRIPTION_TYPES, ...PAID_SUBSCRIPTION_TYPES] as const

export enum PLAN_TYPES {
  STARTER = 'starter',
  GROWTH = 'growth',
  PREMIUM = 'premium',
}

export type SubsriptionType = (typeof SUBSCRIPTION_TYPES)[number]

export const TRANSACTION_PURCHASE_PROVIDERS = ['veritree', 'unfccc', 'plasticBank'] as const
export type TransactionPurchaseProviders = (typeof TRANSACTION_PURCHASE_PROVIDERS)[number]

export interface ISubscriptionItem {
  id: string
  product: SubsriptionType
  amount: number
  startDate: string
  trialEndDate: string
  endDate: string
  _id: string
  updatedAt: string
  createdAt: string
  payerUserId: string
}

export interface IProjectRequestParams {
  country?: string
  projectCategoryId?: string
  type?: ImpactType
  vintage?: number
  lng?: LanguageType
  statuses?: ProjectStatus[]
}

export interface IProjectPrice {
  currency: Omit<CurrencyType, ''>
  price: number
  originalPrice?: number
}

export interface IProjectUpdate {
  availability: number
  prices: IProjectPrice[]
  createdAt: Date
}

export interface IProjectPriceDescriptor {
  platform: BillingPlatform
  externalId: string
  currency: Omit<CurrencyType, ''>
}

export interface IProject {
  projectId: string
  externalId?: string
  projectCategoryId: string
  name: string
  text: string
  link?: string
  externalLink?: string
  registryLink?: string
  registry?: string
  provider?: string
  imageUrl: string
  countries?: string[]
  default: boolean
  status: ProjectStatus
  type: ImpactType
  updates: IProjectUpdate[]
  vintage?: number
  priceDescriptors: IProjectPriceDescriptor[]
  availability: number | null
}

export interface Impact {
  type: ImpactType
  amount: number
}

export interface IAccountImpact extends Impact {
  source: 'account'
}

export interface ITransferredImpact extends Impact {
  source: 'transferred'
  urls: string[]
}

export interface IRelationImpact extends Impact {
  source: 'relation'
}

export interface IImpactActionImpact extends Impact {
  source: 'impactAction'
}

export interface IComparison {
  type: ImpactType
  amount: number
  suffix?: string
  description: string
  link?: string
}

export interface ICarbonComparison extends IComparison {
  type: 'carbon'
  unit: CarbonComparisonUnit
}

export interface IPlasticComparison extends IComparison {
  type: 'plastic'
  unit: PlasticComparisonUnit
}

export interface ITreeComparison extends IComparison {
  type: 'trees'
  unit: TreeComparisonUnit
}

export interface IKelpComparison extends IComparison {
  type: 'kelp'
  unit: KelpComparisonUnit
}

export interface IWaterComparison extends IComparison {
  type: 'water'
  unit: WaterComparisonUnit
}

export const PUBLIC_ACCOUNT_TYPES = ['business', 'personal', 'family']
export type PublicAccountType = (typeof PUBLIC_ACCOUNT_TYPES)[number]

export interface IComparisons {
  comparisons: (ICarbonComparison | IPlasticComparison | ITreeComparison | IKelpComparison)[]
}

export interface IPublicAccount extends IComparisons {
  name: string
  signupDate: string
  accountType: PublicAccountType
  impacts: (IAccountImpact | IRelationImpact | ITransferredImpact | IImpactActionImpact)[]
}

export interface ICommunityAccount extends IComparisons {
  impacts: (IAccountImpact | IRelationImpact)[]
}

export interface IReceiptItem {
  id?: string
  name: string
  url: string
}

export interface IReceipt {
  date: string
  time: number
  type: ImpactType
  receipts: IReceiptItem[]
}

export interface TransactionPurchase {
  id: string
  type: ImpactType
  amount: number
  projectId: string
  receiptUrl: string
  provider: TransactionPurchaseProviders
  periodStartDate: string
  periodEndDate: string
}

interface IHeroContent {
  title: string
  subtitle: string
}

export interface IHeroSettings {
  style: HeroStyleType
  logo: {
    url: string
    visible: boolean
  }
  image: {
    url: string
    visible: boolean
    overlayColor: string
    textColor: string
  }
  content: Record<LanguageType | string, IHeroContent>
  links: Record<SocialMediaType, string>
  memberSince: {
    visible: boolean
  }
}

export interface IGoalSettings {
  goals: string[]
  achievements: string[]
}

export interface IMenuItem {
  lang: LanguageType
  label: string
  link: string
  target?: '_blank' | '_self'
  color: string
  backgroundColor: string
  type: 'button' | 'link'
}

export type ThemeColor = {
  background: string
  text: string
}

export interface IEditorSettings {
  whiteLabel?: {
    domain: string
    navbar: {
      menuItems: IMenuItem[]
      buttons: IMenuItem[]
    }
    footer: {
      menuItems: IMenuItem[]
    }
  }
  joinGreenspark?: {
    visible: boolean
  }
  background: {
    theme: BackgroundTheme
    icon: BackgroundIconType
    themeColor: ThemeColor
  }
  hero: IHeroSettings
  icon: {
    style: IconStyleType
    impactColors: Record<ImpactType, ImpactColor>
    impactDisplay: Record<TopStat | 'hideZeroValues', boolean>
  }
  goalsAchievements: Record<LanguageType | string, IGoalSettings>
}

export interface IPublicPageEditor {
  publicPageEditorSettings: IEditorSettings
  publicPageEditorOptions: {
    heroStyles: HeroStyleType
  }
}

export interface IAccount {
  accountId: string
  accountType: AccountType | ''
  companyName: string
  website: string
  vatId: string
  profileDescription: string
  imageURL?: string
  signupId: string
  signupComplete: boolean
  emailAlreadyUsed: boolean
  birthday: {
    year: number | null
    month: number | null
    day: number | null
  }
  phoneNumber: string
  marketingAccepted: boolean
  termsAndConditions: boolean
  address: {
    street: string
    postCode: string
    city: string
    country: string
  }
  personalPlans: {
    personal: null
    plasticHero: null
    earthHero: number | null
    positiveFamilyAdult: null
    positiveFamilyChild: null
    positiveFamilyPet: null
    earthPositiveFamilyAdult: null
    earthPositiveFamilyChild: null
    earthPositiveFamilyPet: null
  }
  widgetFolderName: string
  widgets: {
    type: string
    url: string
  }[]
  livePageId: string
  signupDate: string
  currency: Currency
  currencyPreChange: Currency
  relationImpact?: Record<ImpactType, number>
  impactActionImpact?: Record<ImpactType, number>
  userFromMarketplace: boolean
  discounts: {
    type: string
    amount: number | null
  }[]
  sustainabilitySite: string
  createdAt: string
}
